import React from 'react'
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Script } from 'gatsby';
import { Modal } from "react-overlays";
import { useState } from 'react';
import * as styles from './shop.module.scss'
import OrderForm from '../../components/Merchandise/OrderForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../helpers/email';

export const Head = () => <title>Merchandise | GROWoneAFRICA&trade;</title>

export default function MerchandisePage({ data }) {
  const shopItems = data.allSanityMerchandise.nodes
  const bankingDetails = data.allSanityCompanyInfo.nodes[0]
  const [ showModal, setShowModal ] = useState(false)
  const [ modalData, setModalData ] = useState()

  const handleClose = () => setShowModal(false);

  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  return (
    <>
      <Script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places`} />
      <main className={styles.shopPage}>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1>GOA Merchandise</h1>

            <p style={{ marginTop: '1rem', fontSize: 'large'}}>Welcome to the GOA Merchandise, your destination for mindful living and sustainable choices.</p>
          </div>

          <div>
            {shopItems.length === 0 ? (
              <p>No products currently available</p>
            ) : (
              shopItems.map((item) => 
                <div className={styles.shopItem} key={item._id}>
                  <div className={styles.itemContent}>
                    {item && item.status === 'sold out' && (
                      <p className={styles.soldOut}>Sold out</p>
                    )}
                    {item && item.status === 'active' && (
                      <p className={styles.available}>Available</p>
                    )}

                    <div>
                      <h1>{item.name}</h1>

                      <p>{item.description}</p>
                      <p className={styles.price}>{formatCurrency(item.price)}</p>
                      {item && item.status === 'active' && (
                        <>
                        <button onClick={() => { setShowModal(true); setModalData(item)}} className={styles.readMoreButton}>
                          <FontAwesomeIcon className={styles.readMoreIcon} icon={faArrowRight} />
                          <span>Order Now</span>
                        </button>
                        </>
                      )}
                    </div>

                  </div>
                  <GatsbyImage
                    image={item.featuredImage.asset.gatsbyImageData}
                    alt={item.name}
                    imgStyle={{ objectFit: 'cover', width: '20rem', height: '20rem' }}
                    style={{ width: '20rem', height: '20rem' }}
                  />
                </div>
              )
            )}
          </div>

          {modalData && 
            <Modal
              className={styles.modal}
              show={showModal}
              onHide={handleClose}
              renderBackdrop={renderBackdrop}
            >
              <div className={styles.modalContent} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <div>
                  <div className={styles.modalHeader}>
                    <div className={styles.modalTitle}>{modalData.name}</div>
                    <div>
                      <span className={styles.closeButton} onClick={handleClose}>x</span>
                    </div>
                  </div>

                  <div className={styles.modalDesc}>
                    <div className={styles.formContainer}>
                      <OrderForm closeModal={handleClose} productData={modalData} bankingDetails={bankingDetails} />
                    </div>
                  </div>
                  
                  <div className={styles.sponsorSection}>
                    <div className={styles.modalFooter}>
                      <button className={styles.secondaryButton} onClick={handleClose}>
                        Close
                      </button>
                    </div>
                  </div>            
                </div>
              </div>
            </Modal>
          }
      </main>
    </>
  )
}

export const query = graphql`
  query MerchandisePageQuery {
    allSanityMerchandise {
      nodes {
        _id
        name
        slug {
          current
        }
        featuredImage {
          asset {
            gatsbyImageData
          }
        }
        description
        price
        productCode
        status
      }
    }
    allSanityCompanyInfo {
      nodes {
        bankingInfo {
          accountName
          accountNumber
          bankName
          branchCode
        }
      }
    }
  }
`;
