export function calculateTotalAmount(quantity, price) {
    return quantity * price;
  }
  
export function generateReferenceNumber() {
  // Generate a random 5-digit number
  const randomDigits = Math.floor(10000 + Math.random() * 90000);

  // Concatenate 'go' with the random number
  return 'goa' + randomDigits;
}

// Outside the main component function
export function formatCurrency(price) {
  // Assuming price is in cents, convert it to rands
  const priceInRands = price / 1;

  // Format as currency with 2 decimal places and use ZAR as the currency code
  return priceInRands.toLocaleString('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
