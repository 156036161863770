// OrderForm.js
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as styles from './form.module.scss'
import ReCAPTCHA from 'react-google-recaptcha';
import verifyCaptcha from '../../../utils/captcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { calculateTotalAmount } from '../../helpers/email';

import { getPaymentMethods, insertOrderInformation } from '../../helpers/database';
import AddressAutocomplete from '../google/address';

const OrderForm = ({ closeModal, productData, bankingDetails }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    deliveryAddress: '',
    quantity: 1,
    specialInstructions: '',
    paymentMethod: '1',
  });
  const [ paymentMethods, setPaymentMethods ] = useState([])
  const [ selectedAddress, setSelectedAddress ] = useState()
  const [ buttonActive, setButtonActive ] = useState(true)
  const [ completed, setCompleted ] = useState(false)
  const [ success, setSuccess ] = useState(false)

  useEffect(() => {
    (async () => {
        const data = await getPaymentMethods();
        setPaymentMethods([...data]);
    })();
}, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCaptchaChange = async (token) => {
    // Save the token or use it in your form submission logic
    try {
      console.log('Verifying Captcha...')
      setButtonActive(false)

      const response = await verifyCaptcha(token);
      // console.log(response)
      if (response.success) {
        console.log('CAPTCHA verification successful');
        // You can update the state or take further actions here if needed
        setButtonActive(false)
      } else if (response.success === false) {
        console.error('CAPTCHA verification failed:', response['error-codes']);     
        // Handle the case where CAPTCHA verification fails (e.g., show an error message)
        setButtonActive(false)
      }
    } catch (error) {
      console.error('Error verifying CAPTCHA:', error);
      setButtonActive(false)
      // Handle any error that might occur during CAPTCHA verification
    }
  };

  const handleAddressSelect = (addressData) => {
    setSelectedAddress(addressData.address);
  };
  
  const handleCardPayment = async (combinedData) => {
    try {
      const response = await fetch('/api/yoco-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(combinedData),
      });
            
      const data = await response.json();

      return data
    } catch (error) {
      console.log(error)
      console.error('Error processing payment:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let total
    let combinedData

    switch (formData.paymentMethod) {
      case '1':
        total = calculateTotalAmount(formData.quantity, productData.price)

        combinedData = {
          ...formData,
          address: selectedAddress,
          total: total,
          product: productData.name,
          bankingDetails: bankingDetails
        };
        
        const result = await insertOrderInformation(combinedData, 'Awaiting Payment');

        if (result.success) {
          const reference = result.reference

          const emailData = {
            ...combinedData,
            reference: 'Goa' + reference,
            status: 'Awaiting Payment',
          };

          setSuccess(true)
          setCompleted(true)

          // SEND EMAILS
          // to Customer
          const response = await fetch('/api/orderConfirmationEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...emailData })
            })
          const responseData = await response.json();    

          // to info@growone.africa
          const response2 = await fetch('/api/sendOrderEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...emailData })
            })
          const responseData2 = await response2.json();  
        break;
        }
      case '2':
        total = calculateTotalAmount(formData.quantity, productData.price)

        combinedData = {
          ...formData,
          address: selectedAddress,
          total: total,
          product: productData.name,
          bankingDetails: bankingDetails
        };

        const yocoResponse = await handleCardPayment(combinedData)

        if (yocoResponse.redirectUrl) {
          window.location.href = yocoResponse.redirectUrl;
        } else {
          setCompleted(true)
        }
        break;
    }
  };

  if (completed) {
    return (
      <main className={styles.thanksPage}>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4rem'}}>
        <h1>{success ? 'Thank you for your order!' : 'Oops!'}</h1>

        <h2>
          {success
              ? ' Your order was completed successfully'
              : ''}
        </h2>

        <p style={{ marginTop: '1rem', fontSize: 'large'}}>
          {success
            ? <><FontAwesomeIcon icon={faEnvelope} /> An email receipt has been sent to the email address you provided</>
            : <><FontAwesomeIcon icon={faTriangleExclamation} /> It seems that something went wrong, and we were unable to process your payment. Please try again or contact us.</>
          }
        </p>

        {success ? (
          <button style={{ marginTop: '2rem', border: '1px solid black', padding: '.5rem', textDecoration: 'none'}} onClick={closeModal}>Continue Shopping</button> 
        ) : (
          <Link style={{ marginTop: '2rem', border: '1px solid black', padding: '.5rem', textDecoration: 'none'}} to="/contact">Contact Us</Link>
        )}
        
      </div>
    </main>
    )
  }
  
  return (
    <form className={styles.orderForm} onSubmit={handleSubmit}>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Email:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Contact Number:
        <input
          type="tel"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
        />
      </label>

      <AddressAutocomplete onSelect={handleAddressSelect} label="Address"/>

      <label>
        Quantity:
        <input
          type="number"
          name="quantity"
          value={formData.quantity}
          onChange={handleInputChange}
          required
        />
      </label>

      <label>
        Special Instructions:
        <textarea
          name="specialInstructions"
          value={formData.specialInstructions}
          onChange={handleInputChange}
        ></textarea>
      </label>

      <label>
        Payment Method:
        <select
          name="paymentMethod"
          value={formData.paymentMethod}
          onChange={handleInputChange}
          required
        >
          {paymentMethods.map((method) => (
            <option key={method.id} value={method.id}>
              {method.name}
            </option>
          ))}
        </select>
      </label>

      <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />

      <button disabled={buttonActive} type="submit">Submit</button>
    </form>
  );
};

export default OrderForm;
