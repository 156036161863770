// AddressAutocomplete.js
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

/*
    STEPS
        // _app.js
            1. add script to _app.js
        // address.js
            2. create this AddressAutocomplete component
        // form.js
            3. import AddressAutocomplete where form is
            4. create handleAddressSelect
                const handleAddressSelect = (addressData) => {
                    // Update the state with the selected address data
                    console.log(addressData)
                    setSelectedAddress(addressData);
                };
            5. add <AddressAutocomplete onSelect={handleAddressSelect} /> in form
*/

const AddressAutocomplete = ({ onSelect, label }) => {
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState()

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = async (selectedAddress) => {
    setLoading(true);
  
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
  
      setAddress(selectedAddress);
  
      // Pass the selected address and coordinates to the parent component
      onSelect({
        address: selectedAddress,
        latitude: latLng.lat,
        longitude: latLng.lng,
      });
    } catch (error) {
      console.error('Error geocoding address:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
        <h1>Loading</h1>
    )
  }

  return (
    <div>
      {label && <label htmlFor="address" style={{ margin: '.5rem 0' }}>{label}</label>}
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input 
              {...getInputProps({
                placeholder: 'Enter your address.',
                style: { padding: '.5rem', margin: '.5rem 0', width: '100%' }
            })}
            />
            <div
                // className="autocomplete-dropdown-container bg-white absolute z-50 ring-1 ring-inset ring-gray-300"
                style={{ backgroundColor: 'white' }}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                        style: { padding: '.5rem'}
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutocomplete;
